import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { get, post, put } from '../../api'
import { GET_USER_BY_ID, GET_MANAGERS, CREATE_USER, UPDATE_USER } from '../../api/endpoints'
import { FormField, SelectField, TextAreaField } from '../../components/Forms/FormFields';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { concatUrlWithId, checkPermission } from '../../utils/commonUtils';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import { UserPermissionStore } from '../../Store/UserPermission';

export const investmentSchema = Yup.object().shape({
    first_name: Yup.string().label('First Name').trim().required('First Name  is required'),
    last_name: Yup.string().label('Last Name').trim().required('Last Name is required'),
    username: Yup.string().label('User Name').trim().required('User Name is required').test('no-spaces', 'Username cannot contain spaces', value => !/\S\s+\S/.test(value)),
    email: Yup.string().label('Email').email('Invalid email address').matches(/^[^\s@.]+(\.[^\s@.]+)*@[^\s@.]+(\.[^\s@.]+)+$/, 'Invalid email address').required('Email is required'),
    phone: Yup.string().label('Mobile Number').trim().required('Mobile Number is required').matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid mobile number'),
    // manager: Yup.string().label('Manager').trim().required('Manager is required'),
});

function UserModelForm({ show, handleClose, mode, editId, onSuccess, headerName, formAction, managerID = false, isInviteManager = false }) {

    const [initialValues, setInitialValues] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        phone: '',
        // password: '',
        manager: '',
        address:'',
        resetToken: "reset_token_value",
    });

    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [managers, setManagers] = useState([]);
    const [userPermissions, setUserPermissions] = useState("");
    const { checkAndUpdatePermissions } = UserPermissionStore();

    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const formattedValue = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        return formattedValue;
    }

    const notify = (message, type) => toast(message, { type });
    const authCheckAndFetch = async () => {
        let tempPermission = await checkAndUpdatePermissions();

        setUserPermissions(tempPermission || []);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!mode) {
                setInitialValues({
                    first_name: '',
                    last_name: '',
                    username: '',
                    email: '',
                    phone: '',
                    // password: '',
                    manager: '',
                    address:'',
                    resetToken: "reset_token_value",
                });
                setEditMode(false);
                setViewMode(false);
            } else if (mode == 'view') {
                setViewMode(true);
            } else if (mode == 'edit') {
                setEditMode(true);
                setViewMode(false);
            } else {
                setEditMode(false);
                setViewMode(false);
            }

            if ((mode == 'view' || mode == 'edit')) {
                try {
                    const response = await get(concatUrlWithId(GET_USER_BY_ID, editId));
                    // Process the fetched data here
                    if (response) {
                        const fullNameParts = response.full_name.split(' - ');
                        const firstName = fullNameParts[0];
                        const lastName = fullNameParts[1];
                        let values = {
                            id: response.id,
                            first_name: firstName,
                            last_name: lastName,
                            username: response.username,
                            email: response.email,
                            phone: response.phone,
                            address:response.address,
                            // password: response.password,
                            manager: response.manager
                        };
                        setInitialValues(values);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                setInitialValues({
                    first_name: '',
                    last_name: '',
                    username: '',
                    email: '',
                    phone: '',
                    // password: '',
                    manager: '',
                    address:'',
                    resetToken: "reset_token_value",
                });
            }
        };
        fetchData();
        if (show) {
            fetchManagerList();
        }
        authCheckAndFetch();

    }, [show, mode, editId]);

    const managerOptions = [
        { label: "None", value: "" },
        ...managers.map(manager => ({
            value: manager.value,
            label: manager.label
        }))
    ];
    const fetchManagerList = async () => {
        try {
            const managerList = await get(GET_MANAGERS);
            if (managerList.managers) {
                setManagers(managerList.managers);
            }
        } catch (error) {
            console.log('Error while fetching users: ', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{mode == 'view' ? `View ${headerName}` : mode === "edit" ? `Update ${headerName}` : `Add ${headerName}`}</Modal.Title>
            </Modal.Header>
            <Formik

                initialValues={initialValues}
                validationSchema={investmentSchema}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    setIsSubmitting(true);
                    try {
                        let response = []
                        const data_userId = editMode ? initialValues?.id : editId;
                        const url = editMode ? concatUrlWithId(UPDATE_USER, data_userId) : CREATE_USER;
                        const bodyPayload = {
                            full_name: `${values.first_name} - ${values.last_name}`,
                            username: values.username.trim(),
                            email: values.email,
                            phone: values.phone,
                            // password: values.password,
                            manager: managerID,
                            address:values.address,
                            isManagerInvite: isInviteManager,
                            resetToken: "reset_token_value",
                        };
                        response = editMode ? await put(url, bodyPayload) : await post(url, bodyPayload);

                        if (response) {

                            notify(`${values.username} ${editMode ? "Updated" : "Added"} Successfully...`, 'success')

                            if (isSaveNew == true) {
                                actions.resetForm();
                                setIsFieldClear(true);
                            } else {
                                handleClose()
                            }

                        } else {
                            editMode ? notify(`User  update failed...`, 'error') : notify(`Create user  failed...`, 'error');
                        }
                    } catch (error) {
                        notify(error?.response?.data?.error, 'error');

                        console.error('API Error:', error);
                    }
                    setIsSubmitting(false);
                }}
            >
                {props => (
                    <>
                        <Modal.Body >
                            <div>
                                <Form>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <FormField viewMode={viewMode} fieldName="first_name" label="First Name" value={initialValues.first_name} maxLength={200} />
                                            <FormField viewMode={viewMode} fieldName="username" label="User Name" value={initialValues.username} maxLength={200} />
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="formrow-holding-company-input">Mobile Number{!viewMode ? <span className='man-start'>*</span> : ''}</label>
                                                {!viewMode && (
                                                    <Field className="form-control" maxLength={14} readOnly={viewMode}
                                                        type="tel" id="formrow-holding-company-input" name="phone"
                                                        onInput={(e) => {
                                                            const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                                            const formattedValue = inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                                            e.target.value = formattedValue;
                                                        }}
                                                    />
                                                )}
                                                {viewMode && <div className='view-label'>{formatPhoneNumber(initialValues.phone)}</div>}
                                                <ErrorMessage name="phone" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <FormField viewMode={viewMode} fieldName="last_name" label="Last Name" value={initialValues.last_name} maxLength={200} />
                                            {/* {checkPermission(userPermissions, 'isAdmin') && <SelectField viewMode={viewMode} fieldName="manager" label="Manager" isRequired={false} value={initialValues.manager} options={managerOptions} values={props.values} setFieldValue={props.setFieldValue} />} */}
                                            <FormField viewMode={viewMode} fieldName="email" label="Email" value={initialValues.email} maxLength={200} />
                                            <TextAreaField viewMode={viewMode} fieldName="address" label="Address" value={initialValues.address} maxLength={300} />
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ justifyContent: 'center' }}>
                            <Button type="reset" variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            {!viewMode && <Button type="submit" variant="primary" disabled={isSubmitting} onClick={() => { props.submitForm(); setIsSaveNew(false); }} className="btn btn-primary w-md">
                                {editMode ? 'Update' : 'Save'}
                            </Button>}
                            {editMode != true && (!viewMode && <Button type="submit" onClick={() => { props.submitForm(); setIsSaveNew(true); }} className="btn btn-primary w-md">{editMode ? 'create' : 'Save and New'}</Button>)}
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>)
}
export default UserModelForm;