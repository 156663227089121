import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { get, put } from '../api';
import { GET_USERS, UPDATE_USER_PERMISSION, GET_USER_PERMISSION, GET_USER_INVESTMENT_PERMISSION, UPDATE_USER_INVESTMENT_PERMISSION } from '../api/endpoints';
import Select from 'react-select';
import { concatUrlWithId, checkPermission } from '../utils/commonUtils';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { UserPermissionStore } from '../Store/UserPermission';

function Footer({PropUserPermissions = [] }) {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [title, setTitle] = useState('');
    const [permissionType, setPermissionType] = useState('');
    const [permissionList, setPermissionList] = useState([]);
    const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
    const [showPermission, setShowPermission] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [userSelectedPermissionIds, setUserSelectedPermissionIds] = useState([]);
    const [permissionBox, setPermissionBox] = useState(false)
    const [showSelectAll, setShowSelectAll] = useState(false)
    const [isInvestmentLvl, setInvestmentLvl] = useState(false)
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const notify = (message, type) => toast(message, { type });
    const { investmentID } = useParams();
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];

    useEffect(() => {
        if (isActive) {
            fetchUserList(permissionType);
        } else {
            setSelectedUser('');
            setShowPermission(false);
            setSelectedPermissions([]);
            setDisabledCheckboxes([]);
        }
    }, [isActive, permissionType]);


    const wrapperRef = useRef(null); // Create a ref for the select area
    // Define mapping for titles and permission types
    const permissionMappings = {
        'overview': { type: 'General',isInvestment: false },
        'add-private-equity': { type: 'RealEstate', isInvestment: false },
        'private-equity-list': { type: 'RealEstate', isInvestment: false },
        'view-private-equity': { type: 'RealEstate', isInvestment: true },
        'edit-private-equity': { type: 'RealEstate', isInvestment: true },
        'add-liquid-asset': { type: 'Stock', isInvestment: false},
        'liquid-asset-list': { type: 'Stock', isInvestment: false },
        'view-liquid-asset': { type: 'Stock', isInvestment: true },
        'edit-liquid-asset': { type: 'Stock', isInvestment: true },
        'add-real-estate-holdings': { type: 'RealEstateHoldings', isInvestment: false },
        'real-estate-holding-list': { type: 'RealEstateHoldings', isInvestment: false },
        'view-real-estate-holdings': { type: 'RealEstateHoldings', isInvestment: true },
        'edit-real-estate-holdings': { type: 'RealEstateHoldings', isInvestment: true},
        'add-royalty-income': { type: 'MineralAssets', isInvestment: false},
        'royalty-income-list': { type: 'MineralAssets', isInvestment: false },
        'view-royalty-income': { type: 'MineralAssets', isInvestment: true },
        'edit-royalty-income': { type: 'MineralAssets', isInvestment: true },
        'add-personal-assets': { type: 'PersonalAssets', isInvestment: false },
        'personal-assets-list': { type: 'PersonalAssets', isInvestment: false },
        'view-personal-assets': { type: 'PersonalAssets', isInvestment: true },
        'edit-personal-assets': { type: 'PersonalAssets', isInvestment: true },
        'archived-list': { type: 'Archived', isInvestment: false },
        'operating-business': { type: 'OperatingBusiness', isInvestment: false },
        'estate-planning': { type: 'EstatePlanning', isInvestment: false },
        'reminder-list': { type: 'ReminderList', isInvestment: false },
    };


    useEffect(() => {
        authCheckAndFetch();

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Remove event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); 
    const authCheckAndFetch = async () => {
        const notNeededUrls = ['users', 'profile','liquid-asset-list','private-equity-list','real-estate-holding-list','royalty-income-list','personal-assets-list','reminder-list'];
        let tempPermission = PropUserPermissions || [];
        if (!tempPermission.length) {
            tempPermission = await checkAndUpdatePermissions();
        }
        if (checkPermission(tempPermission, 'isManager') && !notNeededUrls.includes(urlName)) {
            setPermissionBox(true)
        };
    };

    // Function to handle click outside the select area
    const handleClickOutside = (event) => {
        // Close the popup if clicked outside the `.choose-layout-area` element
        const chooseLayoutArea = document.querySelector('.choose-layout-area');
        if (chooseLayoutArea && !chooseLayoutArea.contains(event.target)) {
            chooseLayoutArea.classList.remove('active');
            setIsActive(false);
        }

    };

    useEffect(() => {
        // Get mapping based on the current URL
        const mapping = permissionMappings[urlName] || permissionMappings['overview'];

        setTitle(mapping?.title || 'Permission List');
        setPermissionType(mapping.type);
        setInvestmentLvl(mapping.isInvestment);
        if((urlName === 'portfolio-summary') || (urlName === 'archived-list')){
            setShowSelectAll(false);
        } else {
            setShowSelectAll(true);
        }

        authCheckAndFetch();

    }, [urlName]);

    //fetch Users
    const fetchUserList = async (permissionType) => {
        try {
            const userList = await get(concatUrlWithId(GET_USERS, `${permissionType}/${isInvestmentLvl}`));
            if (userList.users) {
                setUsers(userList.users);
            }
            if (userList.permissions) {
                let filteredPermissions = [];

                filteredPermissions = userList.permissions;
                setPermissionList(filteredPermissions);
            }
        } catch (error) {
            console.log('Error while fetching users: ', error);
        }
    };
    //fetch user Permission
    const fetchUserPermission = async (permissionType, userID) => {
        let userPermission;
        try {
            if ((urlName.includes('view') || urlName.includes('edit')) && !urlName.includes('overview')) {
                userPermission = await get(concatUrlWithId(GET_USER_INVESTMENT_PERMISSION, permissionType) + '/' + userID + '/' + investmentID);
            } else {
                userPermission = await get(concatUrlWithId(GET_USER_PERMISSION, permissionType) + '/' + userID);
            }
            // userPermission = await get(concatUrlWithId(GET_USER_PERMISSION, permissionType) + '/' + userID);
            if (userPermission) {
                // Remove duplicates
                const userPermissions = userPermission.userPermission;

                setSelectedPermissions(userPermissions)
                setUserSelectedPermissionIds(userPermissions?.length ? userPermissions.map(p => p.PermissionId) : []);
                setShowPermission(true);

                // Extract related_permissionID from userPermission and disable corresponding checkboxes
                const disabledIds = userPermissions.map(permission => permission['permission.related_permissionID']).filter(id => id !== null);
                setDisabledCheckboxes(disabledIds);
            }
        } catch (error) {
            console.log('Error while fetching users: ', error);
        }
    };


    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };

    const handleUserChange = (selectedOption) => {
        setSelectedUser(selectedOption);
        if (selectedOption && selectedOption.value !== '') {
            fetchUserPermission(permissionType, selectedOption.value);
        } else {
            setShowPermission(false);
        }
    };

    const handleSelectAll = () => {
        const selectAllCheckbox = document.getElementById('selectAllCheckbox');
        const isChecked = selectAllCheckbox.checked;

        if (isChecked) {
            // If all permissions are currently selected, deselect all
            const newPermissions = [];
            const disabledIds = [];
            const selectedPermissionsId = [];

            permissionList.forEach(permission => {
                // Check if permission has related_permissionID and add it to disabledIds
                if (permission.related_permissionID !== null && !disabledIds.includes(permission.related_permissionID)) {
                    disabledIds.push(permission.related_permissionID);
                }

                // Add permission ID to selectedPermissions
                selectedPermissionsId.push(permission.id);
            });

            setDisabledCheckboxes(disabledIds);
            setSelectedPermissions(selectedPermissionsId)
            setUserSelectedPermissionIds(selectedPermissionsId);
        } else {
            // If not all permissions are selected, select all
            setDisabledCheckboxes([]);
            setSelectedPermissions([])
            setUserSelectedPermissionIds([]);

        }
    };

    function hasRelativePermission(usrPerm, relativePermission) {
        for (let i = 0; i < permissionList.length; i++) {
            if (permissionList[i].id === usrPerm && permissionList[i].related_permissionID === relativePermission) {
                return true; // Return true if a matching main with the specified sub is found
            }
        }
        return false; // Return false if no matching main with the specified sub is found
    }

    // Test the function
    function testRelativePermission(usrPerm, relPerm) {
        for (let i = 0; i < usrPerm.length; i++) {
            let hasRelative = hasRelativePermission(usrPerm[i], relPerm);
            if (hasRelative) {
                return true; // Stop the loop and return true if a match is found
            }
        }
        return false; // Return false if no match is found
    }


    const handlePermissionChange = (permissionId, checked, userPermission) => {
        const matchID = permissionList.find(permission => permission.id === permissionId);
        let relativePermissionsId = matchID.related_permissionID;
        if (checked) {
            setSelectedPermissions(prev => [...prev, permissionId]);
            let tempUsrSelectedPermission = userSelectedPermissionIds?.length ? [...userSelectedPermissionIds, permissionId] : [permissionId];
            let tempDisabledPermission = disabledCheckboxes?.length ? [...disabledCheckboxes] : [];

            if (!tempUsrSelectedPermission.includes(relativePermissionsId) && !selectedPermissions.includes(relativePermissionsId) && relativePermissionsId !== null) {
                tempUsrSelectedPermission.push(relativePermissionsId);
            }

            if (relativePermissionsId !== null && !tempDisabledPermission.includes(relativePermissionsId)) tempDisabledPermission.push(relativePermissionsId);

            setUserSelectedPermissionIds(tempUsrSelectedPermission)
            setDisabledCheckboxes(tempDisabledPermission)

        } else {
            setSelectedPermissions(prev => prev.filter(id => id !== permissionId));
            let tempUsrSelectedPermission = userSelectedPermissionIds?.length ? [...userSelectedPermissionIds].filter(id => id !== permissionId) : [];
            let tempDisabledPermission = disabledCheckboxes?.length ? [...disabledCheckboxes] : [];

            if (tempUsrSelectedPermission.length == 0 || tempUsrSelectedPermission.length == 1) {
                if (tempUsrSelectedPermission.length == 0 && (relativePermissionsId && !selectedPermissions.includes(relativePermissionsId))) {
                    tempUsrSelectedPermission = [];
                }
                tempDisabledPermission = [];
            } else {
                let checkSameRelativeIdExistInOP = testRelativePermission(tempUsrSelectedPermission, relativePermissionsId);
                if (!checkSameRelativeIdExistInOP) {
                    tempDisabledPermission = tempDisabledPermission.filter(id => id !== relativePermissionsId)
                }
            }

            setUserSelectedPermissionIds(tempUsrSelectedPermission)
            setDisabledCheckboxes(tempDisabledPermission)
        }
    };

    const userOptions = [
        { label: "None", value: "" },
        ...users.map(user => ({
            value: user.value,
            label: user.label
        }))
    ];

    const userUpdatePermission = async (values) => {
        try {
            let url;
            if ((location.pathname.includes('view') || location.pathname.includes('edit')) && !location.pathname.includes('overview')) {
                url = UPDATE_USER_INVESTMENT_PERMISSION;
            } else {
                url = UPDATE_USER_PERMISSION;
            }
            const investmentId = investmentID ? investmentID : null;
            const bodyPayload = {
                UserId: selectedUser.value,
                PermissionIds: userSelectedPermissionIds,
                Permission_type: permissionType,
                InvestmentID: investmentId
            };
            const response = await put(url, bodyPayload);
            if (response) {
                notify(`Permission shared successfully with ${selectedUser.label}`, 'success')
                toggleActiveClass();
            } else {
                notify('Permission Updated failed...', 'error');
            }
        } catch (error) {
            notify(error?.response?.data?.error, 'error');
            console.error('API Error:', error);
        }
    };

    return (
        <>
            {permissionBox && <div className="container-fluid">
                <div className="row no-print">
                    <div className="col-12">
                        <div className={`choose-layout-area ${isActive ? 'active' : ''} footersetting`}>
                            <div className="setting-trigger-icon" id="settingTrigger" onClick={toggleActiveClass}>
                                {isActive ? (
                                    <i className="ti-angle-right"></i>
                                ) : (
                                    <i className="ti-angle-left"></i>
                                )}
                            </div>
                            <div className="choose-layout" id="chooseLayout" ref={wrapperRef}>
                                <div className="quick-setting-tab">
                                    <div className="widgets-todo-list-area">
                                        <h4 className="todo-title todocolor">{title}</h4>
                                        <hr />
                                        <Formik
                                            initialValues={{
                                                selectedUser: '',
                                                selectedPermissions: []
                                            }}
                                            onSubmit={(values, actions) => {
                                                userUpdatePermission(values);
                                            }}
                                        >
                                            <Form id="form-add-todo" className="form-add-todo">
                                                <label className='todocolor'>Select User</label>
                                                <Select
                                                    options={userOptions}
                                                    value={selectedUser}
                                                    placeholder="Select"
                                                    onChange={handleUserChange}
                                                    styles={{ control: styles => ({ ...styles, backgroundColor: 'white', height: '41px', border: '2px solid #ebebeb', fontSize: '14px', borderRadius: '0.5rem', fontWeight: 'normal', zIndex: '9999' }) }}
                                                />
                                                {showPermission &&
                                                    <div id="flaptToDo-list" className="todo-list" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                        <label className='todocolor'>Choose Permission</label>
                                                        <br />
                                                        {showSelectAll && <div style={{ textAlign: 'right', margin: '10px 10px 0 0' }}>
                                                            <label className="ckbox" style={{ cursor: 'pointer' }}>
                                                                <Field
                                                                    id="selectAllCheckbox"
                                                                    type="checkbox"
                                                                    name="selectAll"
                                                                    checked={userSelectedPermissionIds.length === permissionList.length}
                                                                    onChange={handleSelectAll}
                                                                />
                                                                {' '}
                                                                <span style={{ color: 'black' }}>Select All</span>
                                                            </label>
                                                        </div>}
                                                        <div style={{ borderBottom: '1px solid #dcdde3', marginBottom: "5px", padding: '5px' }} ></div>

                                                        {permissionList && permissionList.map(permission => (
                                                            <label key={permission.id} className="ckbox" style={{ marginTop: '8px', cursor: 'pointer' }}>
                                                                <Field
                                                                    type="checkbox"
                                                                    name={`permission-item-${permission.id}`}
                                                                    className="permission-item"
                                                                    value={permission.id}
                                                                    disabled={disabledCheckboxes.includes(permission.id)}
                                                                    checked={userSelectedPermissionIds.includes(permission.id)}
                                                                    onChange={(e) => {
                                                                        handlePermissionChange(permission.id, e.target.checked);
                                                                    }}
                                                                />
                                                                {' '}
                                                                <span style={{ color: 'black' }}>{permission.permission_name}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                }
                                                {showPermission &&
                                                    <div className="row" style={{ marginTop: '30px' }}>
                                                        <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                            <button type="reset" onClick={toggleActiveClass} className="btn btn-secondary w-md">Close</button>
                                                            <button type="submit" className="btn btn-primary w-md">Share</button>
                                                        </div>
                                                    </div>
                                                }
                                            </Form>
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <footer className="footer-area d-sm-flex justify-content-center align-items-center justify-content-between">
                            <div className="copywrite-text">
                                <p className="font-13">Developed by &copy; <a href="#">Truehoch</a></p>
                            </div>
                            <div className="fotter-icon text-center">
                                <p className="mb-0 font-13">2024 &copy; Truehoch</p>
                            </div>
                        </footer> */}
                    </div>
                </div>
            </div>
            }
        </>
    );
}

export default Footer;