import moment from 'moment-timezone';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { reminderOptionType, weeklyOptions, dateOptions } from "../Data/ReminderOptions";
import { GET_INVESTMENTS } from '../api/endpoints';
import { get } from '../api';
/** Toast message */
export const notify = (message, type) => toast(message, { type });

/* Concate Url and Id */
export const concatUrlWithId = (url, id) => `${url}/${id}`;

/* Action Button */
// export const ActionIcon = ({ iconClass, tooltip, onClick, backgroundColor, placement = 'top' }) => (
//     <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>{tooltip}</Tooltip>}>
//         <i className={`btn btn-circle-v1 ${iconClass}`} onClick={onClick} style={{ fontSize: '16px', margin: '0px 5px', color: 'white', background: backgroundColor, cursor: 'pointer' }} ></i>
//     </OverlayTrigger>
// );
export const ActionIcon = ({ iconClass, tooltip, onClick, backgroundColor, count, placement = 'top' }) => (
    <OverlayTrigger placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}>{tooltip}</Tooltip>}>
        <div className="icon-container" style={{ position: 'relative', display: 'inline-block' }}>
            {count > 0 && (
                <span className="count-badge" style={{
                    position: 'absolute',
                    top: '-8px',
                    right: '-5px',
                    background: '#e82529',
                    color: 'white',
                    borderRadius: '50%',
                    padding: '1px 5px',
                    fontSize: '11px',
                    width: 'auto',
                    height: 'auto',
                    zIndex: '1',
                    minWidth: '18px'
                }}>
                    {count}
                </span>
            )}
            <i className={`btn btn-circle-v1 ${iconClass}`} onClick={onClick} style={{
                fontSize: '16px',
                margin: '0px 5px',
                color: 'white',
                background: backgroundColor,
                cursor: 'pointer',
                position: 'relative'
            }} ></i>
        </div>
    </OverlayTrigger>
);

export const ReminderTaskIcon = ({ iconClass, tooltip, onClick, backgroundColor, count, placement = 'top' }) => (
    <OverlayTrigger placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}>{tooltip}</Tooltip>}>
        <div className={`icon-container ${iconClass}`} style={{ position: 'relative', display: 'inline-block' }}>
            {count > 0 && (
                <span className="count-badge" style={{
                    position: 'absolute',
                    top: '-8px',
                    right: '-5px',
                    background: '#e82529',
                    color: 'white',
                    borderRadius: '50%',
                    padding: '1px 5px',
                    fontSize: '11px',
                    width: 'auto',
                    height: 'auto',
                    zIndex: '1',
                    minWidth: '18px'
                }}>
                    {count}
                </span>
            )}
            <i className="bx bx-bell btn btn-circle" onClick={onClick} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: backgroundColor, cursor: 'pointer' }} ></i>
        </div>
    </OverlayTrigger>
);

/* Convert data with format */
export const formatDateAndTime = (date = "", format) => {
    const localTimezone = moment.tz.guess();
    if (!date) {
        return ""; // Return empty string if no date is provided
    }
    return moment.utc(date).tz(localTimezone, true).format(format);
}

/* Add Thousand Seprators */
export const addThousandSeparator = (str) => {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/* Format Currency */
export const formatCurrency = (amount) => {
    if (!amount) return "0.00";

    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
    if (isNaN(numericAmount)) {
        return "-"
        // throw new Error("Invalid input. Please provide a valid number.");
    }

    const [integerPart, decimalPart] = numericAmount.toFixed(2).split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `${formattedInteger}.${decimalPart}`;
};
export const getFileNameFromS3Url = (s3Url) => {
    // Split the S3 URL by '/'
    const urlParts = s3Url.split('/');

    // Extract the last part of the URL, which represents the file name
    let fileName = urlParts[urlParts.length - 1];

    // Decode the URI component to remove encoded characters
    fileName = decodeURIComponent(fileName);

    // Keep only the last 60 characters of the file name
    if (fileName.length > 38) {
        fileName = '...' + fileName.slice(-35); // 57 to accommodate '...'
    }

    return fileName;
};

export const checkPermission = (permissions, action) => {
    return permissions ? permissions.includes(action) : false;
}

export const TitleTrim = ({ title, length = 30 }) => {
    if (title && title.length > length) {
        return (
            <>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>{title}</Tooltip>}>
                    <span >{title.substring(0, length - 3) + '...'}</span>
                </OverlayTrigger>
            </>
        )
    } else {
        return <span>{title}</span>;
    }
}

export const HomeNavigate = (history, response) => {
    if (response?.status == 404) {
        history('/portfolio-summary');
    }
}

export const addReminderOptionLabel = (reminder_type) => {
    let foundLabel = "";
    for (const option of reminderOptionType) {
        if (option.value === reminder_type) {
            foundLabel = option.label;
            break;
        }
    };
    return foundLabel;
}

export const addReminderValueLabel = (type, reminder_value) => {
    let foundLabel = "";
    let tempArr = type == "weekly" ? weeklyOptions : dateOptions
    for (const option of tempArr) {
        if (option.value === reminder_value) {
            foundLabel = option.label;
            break;
        }
    };
    return foundLabel;

}

export const addReminderLabel = (remData = []) => {
    try {
        if (!remData || !remData?.length) return [];

        let tempValue = remData.map((value) => {
            value['reminder_type_label'] = addReminderOptionLabel(value.reminder_type);
            value['reminder_value_label'] = value.reminder_value ? value.reminder_type === 'yearly' ? moment(value.reminder_value).format('MMM-DD') : addReminderValueLabel(value.reminder_type, value.reminder_value) : '';
            value['crud_status'] = 'read';
            return value;
        });
        return tempValue;
    } catch (error) {
        console.log('Error in the add Reminder Lable ', error);
        return remData;
    }

}


export const addValueLabel = (value, optionArray) => {
    let foundLabel = "";
    let tempArr = optionArray;
    for (const option of tempArr) {
        if (option.value === value) {
            foundLabel = option.label;
            break;
        }
    };
    return foundLabel;
}

export const refreshCount = () => {
    const button = document.getElementById('notification-btn-fetch');
    if (button) {
        button.click();
    }
}

export const updateCurrentComponentData = () => {
    const button = document.getElementById('update-remainder-data');
    if (button) {
        button.click();
    }
}

export const getCategory = (id, type) => {

    switch (id) {
        case process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID:
            return "Private Equity";
        case process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID:
            return "Liquid Assets";
        case process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID:
            return "Royalty Income";
        case process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID:
            return "Personal Asset";
        case process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID:
            return "Real Estate Holding";
        default:
            if (type == 'operating_business') {
                return "Operating Business";
            } else if (type == 'estate_planning') {
                return "Estate Planning";
            }
            return "";
    }
};


/**
 * Maps the keys of the input data to the custom headers provided.
 * 
 * @param {Array} data - The data array to map.
 * @param {Object} headersMapping - The mapping of keys to custom headers.
 * @returns {Array} - The transformed data with custom headers.
 */
export const mapDataWithHeaders = (data, headersMapping) => {
    try {
        if (data?.length) {
            return data.map(item => {
                const mappedItem = {};
                Object.keys(item).forEach(key => {
                    // Use the headersMapping to dynamically map the keys
                    if (headersMapping[key]) {
                        mappedItem[headersMapping[key]] = item[key];

                    }
                });
                return mappedItem;
            });
        } else {
            return []
        }

    } catch (e) {
        console.log('Error in the mapDataWithHeaders : ', e)
    }
};

export const cleanURL = (pathNameAfterDomain) => {
    let url = pathNameAfterDomain;
    // Remove '/#'
    if (url.includes('/#')) {
        url = url.replace('/#', '');
    }
    // Remove trailing '/'
    if (url.endsWith('/')) {
        url = url.slice(0, -1);
    }
    return url
}


///// refrennce Filed Configuration 
export const configureRefenceChoices = async (table, filter, labelField, value) => {
    const requestBody = { asset_type: filter };
    const RelatedApi = {
        'liquid-asset': GET_INVESTMENTS
    }
    const investments = await get(RelatedApi[table],requestBody);
    return investments.investments
}